var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "wrapper-section" },
    [
      _vm.sections != null && _vm.sections.length > 0
        ? _c(
            "div",
            { staticClass: "play-list-bg" },
            [
              _c(
                "draggable",
                {
                  attrs: { disabled: !_vm.canEdit, list: _vm.sectionList },
                  on: { end: _vm.end }
                },
                _vm._l(_vm.sectionList, function(item, index) {
                  return _c(
                    "div",
                    {
                      key: index,
                      class:
                        _vm.activeId == item.localId
                          ? "play-item-active"
                          : "play-item",
                      on: {
                        click: function($event) {
                          return _vm.onSectionClick(item.localId)
                        }
                      }
                    },
                    [
                      _c(
                        "el-form",
                        {
                          ref: "titleForm",
                          refInFor: true,
                          staticStyle: { width: "100%" },
                          attrs: { inline: true },
                          nativeOn: {
                            submit: function($event) {
                              $event.preventDefault()
                            }
                          },
                          model: {
                            value: _vm.sectionList,
                            callback: function($$v) {
                              _vm.sectionList = $$v
                            },
                            expression: "sectionList"
                          }
                        },
                        [
                          _c("div", { staticClass: "item-title-bg" }, [
                            _c("div", { staticClass: "section-sn" }, [
                              _vm._v(_vm._s(index + 1))
                            ]),
                            _vm._v(" "),
                            _vm.localId === item.localId && _vm.editItem
                              ? _c(
                                  "span",
                                  { staticClass: "item-title" },
                                  [
                                    item.sectionType == 1
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: {
                                            "icon-class":
                                              "photo-size-select-small"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 2
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: { "icon-class": "image" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 3
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: {
                                            "icon-class": "mask-section"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 4
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: { "icon-class": "gif-section" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 5
                                      ? _c("i", {
                                          staticClass:
                                            "title-icon el-icon-chat-line-square",
                                          staticStyle: { "font-size": "20px" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("el-input", {
                                      ref: "itemTitle",
                                      refInFor: true,
                                      staticClass: "edit-input",
                                      staticStyle: { "max-width": "400px" },
                                      attrs: {
                                        disabled: !_vm.canEdit,
                                        size: "small"
                                      },
                                      on: {
                                        blur: function($event) {
                                          return _vm.updateTitle(item)
                                        }
                                      },
                                      nativeOn: {
                                        keyup: function($event) {
                                          if (
                                            !$event.type.indexOf("key") &&
                                            _vm._k(
                                              $event.keyCode,
                                              "enter",
                                              13,
                                              $event.key,
                                              "Enter"
                                            )
                                          ) {
                                            return null
                                          }
                                          return _vm.updateTitle(item)
                                        }
                                      },
                                      model: {
                                        value: item.title,
                                        callback: function($$v) {
                                          _vm.$set(item, "title", $$v)
                                        },
                                        expression: "item.title"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : _c(
                                  "span",
                                  { staticClass: "item-title" },
                                  [
                                    item.sectionType == 1
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: {
                                            "icon-class":
                                              "photo-size-select-small"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 2
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: { "icon-class": "image" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 3
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: {
                                            "icon-class": "mask-section"
                                          }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 4
                                      ? _c("svg-icon", {
                                          staticClass: "title-icon",
                                          attrs: { "icon-class": "gif-section" }
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    item.sectionType == 5
                                      ? _c("i", {
                                          staticClass:
                                            "title-icon el-icon-chat-line-square",
                                          staticStyle: { "font-size": "20px" }
                                        })
                                      : _vm._e(),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(item.title) +
                                        "\n            "
                                    )
                                  ],
                                  1
                                ),
                            _vm._v(" "),
                            _c(
                              "div",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value: _vm.canEdit,
                                    expression: "canEdit"
                                  }
                                ],
                                staticClass: "item-operation-bg"
                              },
                              [
                                _c("el-button", {
                                  staticClass: "iconfont icon-book-creat",
                                  staticStyle: {
                                    "margin-right": "5px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    disabled: !_vm.canEdit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.handleUpdateTitle(item)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-button", {
                                  staticClass: "iconfont icon-recycle",
                                  staticStyle: {
                                    "margin-right": "5px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    disabled: !_vm.canEdit
                                  },
                                  on: {
                                    click: function($event) {
                                      return _vm.delSection(item.localId)
                                    }
                                  }
                                }),
                                _vm._v(" "),
                                _c("el-button", {
                                  staticClass: "iconfont icon-drag-handle",
                                  staticStyle: {
                                    "margin-right": "5px",
                                    display: "inline-block"
                                  },
                                  attrs: {
                                    type: "text",
                                    size: "mini",
                                    disabled: !_vm.canEdit
                                  },
                                  nativeOn: {
                                    click: function($event) {
                                      return _vm.moveTo(item, $event)
                                    }
                                  }
                                })
                              ],
                              1
                            )
                          ])
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "item-con-bg" }, [
                        item.sectionType !== 5 &&
                        (item.audioAliVid ||
                          item.audioLrcUrl ||
                          item.audioContent ||
                          item.audioContentTrans ||
                          item.videoAliVid ||
                          item.linkUrl)
                          ? _c("div", [
                              item.audioAliVid
                                ? _c(
                                    "div",
                                    {},
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "audio-view common-view"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "common-flex-align"
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "subtitle-icon",
                                                staticStyle: {
                                                  "margin-top": "0"
                                                },
                                                attrs: {
                                                  "icon-class": "audiotrack"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "audio-vid" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.audioAliVid)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          item.audioLength && item.audioSize
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass: "item-size time"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.audioLength) +
                                                      " | " +
                                                      _vm._s(item.audioSize)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("editBtnHover", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit"
                                          }
                                        ],
                                        staticClass: "common-text-align-right",
                                        attrs: {
                                          item: item,
                                          audioAliVidType: _vm.audioAliVidType
                                        },
                                        on: {
                                          receiveLocalId: _vm.receiveLocalId,
                                          receivesection: _vm.receivesection,
                                          receivedialogStatus:
                                            _vm.receivedialogStatus,
                                          receivedialogResourceVisible:
                                            _vm.receivedialogResourceVisible
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.audioLrcUrl && item.audioShowType !== "pic"
                                ? _c(
                                    "div",
                                    { staticClass: "item-con2-box" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-con2 common-view"
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "con2-icon",
                                            staticStyle: { flex: "none" },
                                            attrs: { "icon-class": "subtitles" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "word-break": "break-all",
                                                "font-size": "12px"
                                              }
                                            },
                                            [_vm._v(_vm._s(item.audioLrcUrl))]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("editBtnHover", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit"
                                          }
                                        ],
                                        staticClass: "common-text-align-right",
                                        attrs: {
                                          item: item,
                                          audioLrcUrlType: _vm.audioLrcUrlType
                                        },
                                        on: {
                                          receiveLocalId: _vm.receiveLocalId,
                                          receivesection: _vm.receivesection,
                                          receivedialogStatus:
                                            _vm.receivedialogStatus,
                                          receivedialogResourceVisible:
                                            _vm.receivedialogResourceVisible
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.picturePath && item.audioShowType === "pic"
                                ? _c(
                                    "div",
                                    { staticClass: "item-con2-box" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-con2 common-view"
                                        },
                                        [
                                          _c("i", {
                                            staticClass:
                                              "el-icon-picture con2-icon"
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "common-text" },
                                            [_vm._v(_vm._s(item.picturePath))]
                                          )
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("editBtnHover", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit"
                                          }
                                        ],
                                        staticClass: "common-text-align-right",
                                        attrs: {
                                          item: item,
                                          linkUrlType: "picturePath"
                                        },
                                        on: {
                                          receiveLocalId: _vm.receiveLocalId,
                                          receivesection: _vm.receivesection,
                                          receivedialogStatus:
                                            _vm.receivedialogStatus,
                                          receivedialogResourceVisible:
                                            _vm.receivedialogResourceVisible
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.audioContent
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "item-con2-box",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-con2 common-view"
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "con2-icon",
                                            attrs: {
                                              "icon-class": "sticky-note"
                                            }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "common-text" },
                                            [_vm._v(_vm._s(item.audioContent))]
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.audioContentTrans
                                ? _c(
                                    "div",
                                    {
                                      staticClass: "item-con2-box",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "con2-box common-view audio-trans-view"
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "con2-icon",
                                            attrs: { "icon-class": "translate" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "audio-trans-list" },
                                            _vm._l(
                                              _vm.getAudioTrans(
                                                item.audioContentTrans
                                              ),
                                              function(transItem, transIndex) {
                                                return _c(
                                                  "div",
                                                  {
                                                    key: transIndex,
                                                    staticClass: "common-text"
                                                  },
                                                  [_vm._v(_vm._s(transItem))]
                                                )
                                              }
                                            ),
                                            0
                                          )
                                        ],
                                        1
                                      )
                                    ]
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.videoAliVid
                                ? _c(
                                    "div",
                                    { staticClass: "item-con1-box" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "audio-view common-view"
                                        },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "common-flex-align"
                                            },
                                            [
                                              _c("svg-icon", {
                                                staticClass: "subtitle-icon",
                                                staticStyle: {
                                                  "margin-top": "0"
                                                },
                                                attrs: {
                                                  "icon-class": "ondemand-video"
                                                }
                                              }),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                { staticClass: "audio-vid" },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.videoAliVid)
                                                  )
                                                ]
                                              )
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          item.videoLength && item.videoSize
                                            ? _c(
                                                "span",
                                                {
                                                  staticClass: "item-size time"
                                                },
                                                [
                                                  _vm._v(
                                                    _vm._s(item.videoLength) +
                                                      " | " +
                                                      _vm._s(item.videoSize)
                                                  )
                                                ]
                                              )
                                            : _vm._e()
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c("editBtnHover", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit"
                                          }
                                        ],
                                        staticClass: "common-text-align-right",
                                        attrs: {
                                          item: item,
                                          videoAliVidType: _vm.videoAliVidType
                                        },
                                        on: {
                                          receiveLocalId: _vm.receiveLocalId,
                                          receivesection: _vm.receivesection,
                                          receivedialogStatus:
                                            _vm.receivedialogStatus,
                                          receivedialogResourceVisible:
                                            _vm.receivedialogResourceVisible
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.linkUrl
                                ? _c(
                                    "div",
                                    { staticClass: "item-con1-box" },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass: "item-con1 common-view"
                                        },
                                        [
                                          _c("svg-icon", {
                                            staticClass: "subtitle-icon",
                                            attrs: { "icon-class": "url-link" }
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "span",
                                            { staticClass: "common-text" },
                                            [_vm._v(_vm._s(item.linkUrl))]
                                          )
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c("editBtnHover", {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value: _vm.canEdit,
                                            expression: "canEdit"
                                          }
                                        ],
                                        staticClass: "common-text-align-right",
                                        attrs: {
                                          item: item,
                                          linkUrlType: _vm.linkUrlType
                                        },
                                        on: {
                                          receiveLocalId: _vm.receiveLocalId,
                                          receivesection: _vm.receivesection,
                                          receivedialogStatus:
                                            _vm.receivedialogStatus,
                                          receivedialogResourceVisible:
                                            _vm.receivedialogResourceVisible
                                        }
                                      })
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              item.audioAliVid
                                ? _c(
                                    "div",
                                    {
                                      staticClass:
                                        "audio-operation common-view",
                                      staticStyle: { "margin-top": "10px" }
                                    },
                                    [
                                      _c("span", [_vm._v("连读")]),
                                      _vm._v(" "),
                                      _c("el-switch", {
                                        staticClass: "switch",
                                        attrs: { size: "mimi" },
                                        on: {
                                          change: function($event) {
                                            return _vm.handleContinuityPlay(
                                              item
                                            )
                                          }
                                        },
                                        model: {
                                          value: item.continuityPlay,
                                          callback: function($$v) {
                                            _vm.$set(
                                              item,
                                              "continuityPlay",
                                              $$v
                                            )
                                          },
                                          expression: "item.continuityPlay"
                                        }
                                      }),
                                      _vm._v(" "),
                                      item.continuityPlay
                                        ? _c("span", {
                                            staticClass: "audio-border"
                                          })
                                        : _vm._e(),
                                      _vm._v(" "),
                                      item.continuityPlay
                                        ? _c(
                                            "el-checkbox",
                                            {
                                              staticClass: "audio-check",
                                              attrs: { size: "mimi" },
                                              on: {
                                                change: function($event) {
                                                  return _vm.handleContinuityPlay(
                                                    item
                                                  )
                                                }
                                              },
                                              model: {
                                                value: item.endReading,
                                                callback: function($$v) {
                                                  _vm.$set(
                                                    item,
                                                    "endReading",
                                                    $$v
                                                  )
                                                },
                                                expression: "item.endReading"
                                              }
                                            },
                                            [_vm._v("结束")]
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "only-vip-top" },
                                [
                                  _vm._v(
                                    "\n              仅VIP可用：\n              "
                                  ),
                                  _c("el-switch", {
                                    attrs: { "active-color": "#13ce66" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleContinuityPlay(item)
                                      }
                                    },
                                    model: {
                                      value: item.vipOnly,
                                      callback: function($$v) {
                                        _vm.$set(item, "vipOnly", $$v)
                                      },
                                      expression: "item.vipOnly"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : item.sectionData &&
                            _vm.resourceTypeList.indexOf(item.resourceType) >= 0
                          ? _c("div", { staticClass: "content-precinct" }, [
                              _c(
                                "div",
                                { staticClass: "content-precinct-title" },
                                [
                                  item.resourceType === 12
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont menu-icon icon-wenjianbao"
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 13
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont menu-icon icon-hanziku"
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 14
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont menu-icon icon-ciku"
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 16
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont menu-icon icon-kuozhanxinxi_danciku"
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 17 ||
                                  item.resourceType === 18
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont menu-icon icon-shiti",
                                        staticStyle: { "font-weight": "bold" }
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 19
                                    ? _c("i", {
                                        staticClass:
                                          "iconfont icon-gongdantupian menu-icon "
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _vm._l(item.sectionData, function(
                                    item1,
                                    index
                                  ) {
                                    return _c(
                                      "span",
                                      {
                                        directives: [
                                          {
                                            name: "show",
                                            rawName: "v-show",
                                            value:
                                              item.resourceType !== 17 &&
                                              item.resourceType !== 18,
                                            expression:
                                              "item.resourceType !== 17 && item.resourceType !== 18"
                                          }
                                        ]
                                      },
                                      [
                                        _vm._v(_vm._s(item1.value) + " "),
                                        item.sectionData.length > 1 &&
                                        index < item.sectionData.length - 1
                                          ? _c("span", [_vm._v("、")])
                                          : _vm._e()
                                      ]
                                    )
                                  }),
                                  _vm._v(" "),
                                  item.resourceType === 17
                                    ? _c("span", [
                                        _vm._v(
                                          "共" +
                                            _vm._s(item.sectionData.length) +
                                            "题"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 18
                                    ? _c("span", [
                                        _vm._v(
                                          "共" +
                                            _vm._s(item.sectionData.length) +
                                            "套"
                                        )
                                      ])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 19
                                    ? _c("span", [
                                        _vm._v(
                                          "共" +
                                            _vm._s(
                                              item.sectionData.imageList
                                                ? item.sectionData.imageList.split(
                                                    ","
                                                  ).length
                                                : ""
                                            ) +
                                            "张"
                                        )
                                      ])
                                    : _vm._e()
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "precinct-resource-type" },
                                [
                                  item.resourceType === 12
                                    ? _c("span", [_vm._v("内容")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 13
                                    ? _c("span", [_vm._v("字库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 14
                                    ? _c("span", [_vm._v("词库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 16
                                    ? _c("span", [_vm._v("单词库")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 17
                                    ? _c("span", [_vm._v("试题")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 18
                                    ? _c("span", [_vm._v("试卷")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.resourceType === 19
                                    ? _c("span", [_vm._v("图片")])
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c("editBtnHover", {
                                    attrs: {
                                      item: item,
                                      "link-url-type": "sectionData"
                                    },
                                    on: { handleEdit: _vm.handleEdit }
                                  })
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                "div",
                                { staticClass: "only-vip-top" },
                                [
                                  _vm._v(
                                    "\n              仅VIP可用：\n              "
                                  ),
                                  _c("el-switch", {
                                    attrs: { "active-color": "#13ce66" },
                                    on: {
                                      change: function($event) {
                                        return _vm.handleContinuityPlay(item)
                                      }
                                    },
                                    model: {
                                      value: item.vipOnly,
                                      callback: function($$v) {
                                        _vm.$set(item, "vipOnly", $$v)
                                      },
                                      expression: "item.vipOnly"
                                    }
                                  })
                                ],
                                1
                              )
                            ])
                          : item.sectionType !== 5
                          ? _c(
                              "div",
                              [
                                _c(
                                  "el-button",
                                  {
                                    staticClass: "relevance-btn",
                                    attrs: { disabled: !_vm.canEdit },
                                    on: {
                                      click: function($event) {
                                        return _vm.handleCreate(item)
                                      }
                                    }
                                  },
                                  [_vm._v("关联点学资源")]
                                )
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  "div",
                                  { staticClass: "comment-section-tips" },
                                  [_vm._v("主题")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "edit-input",
                                  staticStyle: { "max-width": "400px" },
                                  attrs: {
                                    placeholder: "请输入评论主题",
                                    maxlength: "20",
                                    size: "small",
                                    "show-word-limit": "",
                                    clearable: ""
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.updateCommentTheme(item)
                                    }
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.updateCommentTheme(item)
                                    }
                                  },
                                  model: {
                                    value: item.commentTheme,
                                    callback: function($$v) {
                                      _vm.$set(item, "commentTheme", $$v)
                                    },
                                    expression: "item.commentTheme"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "comment-section-tips" },
                                  [_vm._v("描述")]
                                ),
                                _vm._v(" "),
                                _c("el-input", {
                                  staticClass: "edit-input",
                                  staticStyle: { "max-width": "400px" },
                                  attrs: {
                                    placeholder: "请输入评论描述",
                                    maxlength: "144",
                                    autosize: { minRows: 3, maxRows: 5 },
                                    type: "textarea",
                                    size: "small",
                                    "show-word-limit": ""
                                  },
                                  on: {
                                    blur: function($event) {
                                      return _vm.updateCommentDetail(item)
                                    }
                                  },
                                  nativeOn: {
                                    keyup: function($event) {
                                      if (
                                        !$event.type.indexOf("key") &&
                                        _vm._k(
                                          $event.keyCode,
                                          "enter",
                                          13,
                                          $event.key,
                                          "Enter"
                                        )
                                      ) {
                                        return null
                                      }
                                      return _vm.updateCommentDetail(item)
                                    }
                                  },
                                  model: {
                                    value: item.commentDetail,
                                    callback: function($$v) {
                                      _vm.$set(item, "commentDetail", $$v)
                                    },
                                    expression: "item.commentDetail"
                                  }
                                }),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "comment-section-tips" },
                                  [_vm._v("评论必含内容")]
                                ),
                                _vm._v(" "),
                                _c(
                                  "el-radio-group",
                                  {
                                    on: {
                                      change: function($event) {
                                        return _vm.handleContinuityPlay(item)
                                      }
                                    },
                                    model: {
                                      value: item.commentContentType,
                                      callback: function($$v) {
                                        _vm.$set(
                                          item,
                                          "commentContentType",
                                          $$v
                                        )
                                      },
                                      expression: "item.commentContentType"
                                    }
                                  },
                                  [
                                    _c("el-radio", { attrs: { label: "1" } }, [
                                      _vm._v("不限")
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "2" } }, [
                                      _vm._v("文字")
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "3" } }, [
                                      _vm._v("语音")
                                    ]),
                                    _vm._v(" "),
                                    _c("el-radio", { attrs: { label: "4" } }, [
                                      _vm._v("图片")
                                    ])
                                  ],
                                  1
                                )
                              ],
                              1
                            )
                      ])
                    ],
                    1
                  )
                }),
                0
              )
            ],
            1
          )
        : _c("div", { staticClass: "no-info-bg" }, [
            _vm._v("设置点学区后，可关联资源")
          ]),
      _vm._v(" "),
      _vm.dialogResourceVisible
        ? _c("resourceDialog", {
            attrs: {
              "local-id": _vm.localId,
              "section-obj": _vm.section,
              "dialog-resource": _vm.dialogResourceVisible,
              "dialog-status-tag": _vm.dialogStatus
            },
            on: {
              "update:dialogResource": function($event) {
                _vm.dialogResourceVisible = $event
              },
              "update:dialog-resource": function($event) {
                _vm.dialogResourceVisible = $event
              }
            }
          })
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }