"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

require("core-js/modules/es7.object.get-own-property-descriptors");

require("core-js/modules/web.dom.iterable");

require("core-js/modules/es6.object.keys");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;

var _toConsumableArray2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/toConsumableArray"));

var _defineProperty2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/defineProperty"));

var _typeof2 = _interopRequireDefault(require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime-corejs2/helpers/typeof"));

var _collect = _interopRequireDefault(require("collect.js"));

var _decimal = _interopRequireDefault(require("decimal.js"));

var _sortablejs = require("sortablejs");

var _priceParser = require("@taozhi/price-parser");

var _index = _interopRequireDefault(require("../../../../../Resource/SelectResourceDialog/index"));

var _PriceInputDiscount = _interopRequireDefault(require("./PriceInputDiscount"));

var _PriceInput = _interopRequireDefault(require("./PriceInput"));

var _index2 = _interopRequireDefault(require("../../../../../ProductMarketplace/PopUp/ComboAddPhysicalItems/index"));

function ownKeys(object, enumerableOnly) { var keys = Object.keys(object); if (Object.getOwnPropertySymbols) { var symbols = Object.getOwnPropertySymbols(object); if (enumerableOnly) { symbols = symbols.filter(function (sym) { return Object.getOwnPropertyDescriptor(object, sym).enumerable; }); } keys.push.apply(keys, symbols); } return keys; }

function _objectSpread(target) { for (var i = 1; i < arguments.length; i++) { var source = arguments[i] != null ? arguments[i] : {}; if (i % 2) { ownKeys(Object(source), true).forEach(function (key) { (0, _defineProperty2.default)(target, key, source[key]); }); } else if (Object.getOwnPropertyDescriptors) { Object.defineProperties(target, Object.getOwnPropertyDescriptors(source)); } else { ownKeys(Object(source)).forEach(function (key) { Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key)); }); } } return target; }

var _default2 = {
  name: 'CV_Product_Product_EditProduct_EditBaseInfo_ProductCombinationContent',
  components: {
    SelectResourceDialog: _index.default,
    PriceInputDiscount: _PriceInputDiscount.default,
    PriceInput: _PriceInput.default,
    ComboAddPhysicalItems: _index2.default
  },
  props: {
    value: {
      type: Array,
      default: function _default() {
        return [];
      }
    }
  },
  data: function data() {
    return {
      outerVisible: false,
      outerPhysicalObjectVisible: false,
      myTableHeaderCellStyle: {
        'background-color': 'rgba(246, 247, 251, 1)'
      }
    };
  },
  computed: {
    valueProxy: {
      get: function get() {
        return (0, _typeof2.default)(this.value) === 'object' && this.value !== null && Array.isArray(this.value) ? this.value : [];
      },
      set: function set(value) {
        this.$emit('input', value);
      }
    },
    excludeData: function excludeData() {
      return (0, _collect.default)(this.valueProxy).map(function (i) {
        return {
          resourceOpenId: i.openId
        };
      }).all();
    },
    sumPrice: function sumPrice() {
      // 有没有带规格的
      var c = (0, _collect.default)(this.valueProxy);

      if ((0, _collect.default)(this.valueProxy).contains(function (i) {
        return i.productType === 1 && i.isHasSpecific;
      })) {
        return '--';
      } //


      var res = c.reduce(function (r, i) {
        return r.add((0, _priceParser.calcPriceByDiscount)(i.orginalPrice, i.price));
      }, new _decimal.default('0'));
      return (0, _priceParser.priceToYuanFixed)(res);
    }
  },
  mounted: function mounted() {
    this.drag();
  },
  methods: {
    drag: function drag() {
      var _this = this;

      var el = document.querySelectorAll('.combination-products-table tbody')[0];

      _sortablejs.Sortable.create(el, {
        onEnd: function onEnd(e) {
          var arr = _this.valueProxy;
          arr.splice(e.newIndex, 0, arr.splice(e.oldIndex, 1)[0]);

          _this.$nextTick(function () {
            _this.valueProxy = arr;
          });
        }
      });
    },
    addgiftevent: function addgiftevent(data) {
      var dataParsed = (0, _collect.default)(data).map(function (j) {
        return _objectSpread(_objectSpread({}, j), {}, {
          openId: j.resourceOpenId,
          title: j.resourceTitle,
          orginalPrice: j.price,
          productType: 0,
          price: 1
        });
      }).map(function (i) {
        return (0, _collect.default)(i).only(['openId', 'title', 'resourceType', 'orginalPrice', 'productType', 'price']).all();
      }).all();
      this.valueProxy = [].concat((0, _toConsumableArray2.default)(this.valueProxy), (0, _toConsumableArray2.default)(dataParsed));
    },
    addComboGiftEvent: function addComboGiftEvent(data) {
      var dataParsed = (0, _collect.default)(data).map(function (j) {
        return _objectSpread(_objectSpread({}, j), {}, {
          openId: j.productOpenId,
          resourceType: 20,
          productType: 1,
          isHasSpecific: j.specificType !== 0,
          price: 1
        });
      }).map(function (i) {
        return (0, _collect.default)(i).only(['openId', 'title', 'resourceType', 'productType', 'orginalPrice', 'isHasSpecific', 'price']).all();
      }).all();
      this.valueProxy = [].concat((0, _toConsumableArray2.default)(this.valueProxy), (0, _toConsumableArray2.default)(dataParsed));
    },
    resourceTypeFormatter: function resourceTypeFormatter(row, column, cellValue) {
      return {
        3: '点学书',
        4: '专辑',
        6: 'VIP',
        8: '文件包',
        10: '文件'
      }[cellValue];
    },
    priceFormatter: function priceFormatter(row, column, cellValue) {
      if (row.productType === 1 && row.isHasSpecific) {
        return '--';
      }

      return (0, _priceParser.priceToYuanFixed)(cellValue);
    },
    productTypeFormatter: function productTypeFormatter(row, column, cellValue) {
      return cellValue ? '实物' : '数字';
    },
    isHasSpecificFormatter: function isHasSpecificFormatter(row, column, cellValue) {
      if (row.productType === 0) {
        return '--';
      }

      return cellValue ? '有' : '无';
    },
    textFormatter: function textFormatter(row, column, cellValue) {
      if (typeof cellValue !== 'string') {
        return '--';
      }

      if (cellValue.trim().length < 1) {
        return '--';
      }

      return cellValue;
    },
    // 删了
    toRemove: function toRemove(index) {
      (0, _collect.default)(this.valueProxy).forget(index);
    },
    elTableCellClassName: function elTableCellClassName(_ref) {
      var row = _ref.row,
          columnIndex = _ref.columnIndex;

      if (columnIndex !== 1) {
        return;
      }

      return row.productType ? 'productType-combination' : 'productType-number';
    }
  }
};
exports.default = _default2;