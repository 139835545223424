"use strict";

var _interopRequireDefault = require("/root/workspace/tao-zhi_mp-admin_Nb7A/node_modules/@babel/runtime/helpers/interopRequireDefault");

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getFollowBookList = getFollowBookList;
exports.getFollowBookDetail = getFollowBookDetail;
exports.followBookToDraft = followBookToDraft;
exports.followBookToDraftById = followBookToDraftById;
exports.followBookToReview = followBookToReview;
exports.followBookToReviewById = followBookToReviewById;
exports.followBookHidden = followBookHidden;
exports.followBookCancelHidden = followBookCancelHidden;
exports.followBookStopSale = followBookStopSale;
exports.followBookCancelStopSale = followBookCancelStopSale;
exports.followBookReview = followBookReview;
exports.followBookBatchReview = followBookBatchReview;
exports.getFollowBookStatusCount = getFollowBookStatusCount;
exports.setFollowBookSequence = setFollowBookSequence;
exports.followBookCancelSequence = followBookCancelSequence;
exports.followBookRecovery = followBookRecovery;
exports.followBookBatchRecovery = followBookBatchRecovery;
exports.delFollowBook = delFollowBook;
exports.followBookBatchSetAttribute = followBookBatchSetAttribute;
exports.followBookBatchIndependentSale = followBookBatchIndependentSale;
exports.followBookReviewPass = followBookReviewPass;
exports.followBookReject = followBookReject;
exports.followBookBatchRefuse = followBookBatchRefuse;
exports.followBookSavePublish = followBookSavePublish;
exports.followBookSaveReview = followBookSaveReview;
exports.getFollowBookResourceList = getFollowBookResourceList;
exports.getFollowBookTitleList = getFollowBookTitleList;
exports.submitFollowBooks = submitFollowBooks;
exports.followBookToSaveById = followBookToSaveById;
exports.updateFollowBookEncode = updateFollowBookEncode;
exports.followBookBatchRecycle = followBookBatchRecycle;
exports.followBookBatchRevoke = followBookBatchRevoke;
exports.followBookBatchHidden = followBookBatchHidden;
exports.followBookBatchCancelHidden = followBookBatchCancelHidden;
exports.followBookBatchStopSale = followBookBatchStopSale;
exports.followBookBatchCancelStopSale = followBookBatchCancelStopSale;
exports.addRole = addRole;
exports.getRoleList = getRoleList;
exports.removeRole = removeRole;
exports.followBookAddContent = followBookAddContent;
exports.changeRole = changeRole;
exports.partnerHaveNumber = partnerHaveNumber;
exports.partnerTags = partnerTags;
exports.getAiCoverByRole = getAiCoverByRole;

var _main = _interopRequireDefault(require("../utils/request/main"));

var request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/english-conversation/admin/"));
var Request = (0, _main.default)("".concat(process.env.VUE_APP_BASE_API, "/rich-text-management/admin/text"));
/**
 * 获取英语会话列表
 * @param {Object} params
 * @returns {Promise<*>}
 */

function getFollowBookList(params) {
  return request.get('/conversation/request/list', {
    params: params
  });
}
/**
 * 获取英语会话列表(用于商品)
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFollowBookResourceList(params) {
  return request.get('/conversation/order/list', {
    params: params
  });
}
/**
 * 获取英语会话详情
 * @param {String} openId
 * @returns {Promise<*>}
 */


function getFollowBookDetail(openId) {
  return request.get("/conversation/request/".concat(openId));
}
/**
 * 保存至草稿箱(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookToDraft(data) {
  return request.post('/conversation/request/drafts', data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookToDraftById(openId, data) {
  return request.patch("/conversation/request/".concat(openId, "/drafts"), data);
}
/**
 * 保存至草稿箱通过Id(修改)
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookToSaveById(openId, data) {
  return request.patch("/conversation/request/".concat(openId, "/unChange"), data);
}
/**
 * 保存至提交审核
 * @param {Object} data
 * @returns  {Promise<*>}
 */


function followBookToReview(data) {
  return request.post('/conversation/request/commit', data);
}
/**
 * 保存至提交审核
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookToReviewById(openId, data) {
  return request.patch("/conversation/request/".concat(openId, "/commit"), data);
}
/**
 * 英语会话隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookHidden(openId, params) {
  return request.post("/conversation/request/".concat(openId, "/hidden"), undefined, {
    params: params
  });
}
/**
 * 英语会话取消隐藏
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookCancelHidden(openId, params) {
  return request.post("/conversation/request/".concat(openId, "/cancelHidden"), undefined, {
    params: params
  });
}
/**
 * 英语会话停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookStopSale(openId, params) {
  return request.post("/conversation/request/".concat(openId, "/stopSale"), undefined, {
    params: params
  });
}
/**
 * 英语会话取消停售
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookCancelStopSale(openId, params) {
  return request.post("/conversation/request/".concat(openId, "/cancelStopSale"), undefined, {
    params: params
  });
}
/**
 * 英语会话-提交审核
 * @param {String} openId
 * @returns {Promise<*>}
 */


function followBookReview(openId) {
  return request.post("/conversation/request/".concat(openId, "/commit"));
}
/**
 * 英语会话-批量提交审核
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function followBookBatchReview(openIds) {
  return request.post('/conversation/request/reviews', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 获取不同状态下的英语会话数量
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFollowBookStatusCount(params) {
  return request.get('/conversation/request/count', {
    params: params
  });
}
/**
 * 设置推荐
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function setFollowBookSequence(openId, params) {
  return request.patch("/conversation/request/".concat(openId, "/sequence"), undefined, {
    params: params
  });
}
/**
 * 取消推荐
 * @param {String} openId
 * @returns  {Promise<*>}
 */


function followBookCancelSequence(openId) {
  return request.patch("/conversation/request/".concat(openId, "/cancelSequence"));
}
/**
 * 英语会话-恢复
 * @param {String} openId
 * @returns {Promise<*>}
 */


function followBookRecovery(openId) {
  return request.post("/conversation/request/".concat(openId, "/recovery"));
}
/**
 * 批量恢复到草稿箱
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchRecovery(openIds) {
  return request.post('/conversation/request/recovers', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量保存到回收站
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchRecycle(openIds) {
  return request.post('/conversation/request/batch/recycle', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 删除
 * @param {String} openId
 * @returns {Promise<*>}
 */


function delFollowBook(openId) {
  return request.delete("/conversation/request/".concat(openId));
}
/**
 * 批量设置属性
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookBatchSetAttribute(params) {
  return request.patch('/conversation/request/attribute', undefined, {
    params: params
  });
}
/**
 * 批量修改是否独立售卖
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookBatchIndependentSale(params) {
  return request.patch('/conversation/request/independentSale', undefined, {
    params: params
  });
}
/**
 * 英语会话-审核通过
 * @param {String} openId
 * @returns {Promise<*>}
 */


function followBookReviewPass(openId) {
  return request.post("/conversation/request/".concat(openId, "/review"));
}
/**
 * 英语会话-保存并发布(新建)
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookSavePublish(data) {
  return request.post('/conversation/request/review', data);
}
/**
 * 英语会话-保存并发布（修改）
 * @param {String} openId
 * @param {Object} data
 * @returns {Promise<*>}
 */


function followBookSaveReview(openId, data) {
  return request.patch("/conversation/request/".concat(openId, "/review"), data);
}
/**
 * 英语会话-驳回
 * @param {String} openId
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookReject(openId, params) {
  return request.post("/conversation/request/".concat(openId, "/reject"), undefined, {
    params: params
  });
}
/**
 * 英语会话-批量驳回
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookBatchRefuse(params) {
  return request.post('/conversation/request/rejects', undefined, {
    params: params
  });
}
/**
 * 获取标题列表
 * @param {Object} params
 * @returns {Promise<*>}
 */


function getFollowBookTitleList(params) {
  return request.get('/conversation/request/repeat/title', {
    params: params
  });
}
/**
 * 批量提交审核
 * @param {Object} params
 * @returns {Promise<*>}
 */


function submitFollowBooks(openIds) {
  return request.post('/conversation/request/commits', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 修改编码信息
 * @param {String} resourceOpenId
 * @param {String} resourceEncode
 * @returns {Promise<*>}
 */


function updateFollowBookEncode(resourceOpenId, resourceEncode) {
  return request.patch("/conversation/resourceEncode/".concat(resourceOpenId), undefined, {
    params: {
      resourceEncode: resourceEncode
    }
  });
}
/**
 * 撤销发布
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchRevoke(openIds) {
  return request.post('conversation/request/batch/revoke', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchHidden(openIds) {
  return request.patch('/conversation/request/batch/hidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量取消隐藏
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchCancelHidden(openIds) {
  return request.patch('/conversation/request/batch/cancelHidden', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 批量停售
 * @param {Object} params
 * @returns {Promise<*>}
 */


function followBookBatchStopSale(params) {
  return request.patch('/conversation/request/batch/stopSale', undefined, {
    params: params
  });
}
/**
 * 批量取消停售
 * @param {String} openIds
 * @returns {Promise<*>}
 */


function followBookBatchCancelStopSale(openIds) {
  return request.patch('/conversation/request/batch/cancelStopSale', undefined, {
    params: {
      openIds: openIds
    }
  });
}
/**
 * 增加角色
 * @param {String} params
 * @returns {Promise<*>}
 */


function addRole(params) {
  return request.post('/role/save', params);
}
/**
 * 获取角色列表
 * @param {String} params
 * @returns {Promise<*>}
 */


function getRoleList(params) {
  return request.get('/role/list', {
    params: params
  });
}
/**
 * 删除角色
 * @param {String} id
 * @returns {Promise<*>}
 */


function removeRole(id) {
  return request.delete("/role/".concat(id));
}

function followBookAddContent(bookOpenId, params) {
  return request.post("content/save/".concat(bookOpenId), params);
}
/**
 * 角色(修改)
 * @param {String} id
 * @param {Object} params
 * @returns {Promise<*>}
 */


function changeRole(id, params) {
  return request.patch("role/update/".concat(id), params);
}
/**
 * 获取各商户角色库数量
 * @returns {AxiosPromise<any>}
 */


function partnerHaveNumber() {
  return request.get("role/partnerHaveNumber");
}
/**
 * 获取商户标签
 * @returns {AxiosPromise<any>}
 */


function partnerTags(params) {
  return request.get("role/partnerTags", {
    params: params
  });
}
/**
 * 根据角色图片和描述获取ai图片
 * @returns {AxiosPromise<any>}
 */


function getAiCoverByRole(params) {
  return request.get('role/getNewImageAddress', {
    params: params
  });
}