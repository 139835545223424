var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "download-box txt-width850" },
    [
      _c(
        "el-form-item",
        {
          attrs: {
            label: (_vm.valueProxy.readableType === 2 ? "音" : "视") + "频下载"
          }
        },
        [
          _c("el-switch", {
            attrs: { disabled: _vm.allowEdit, "active-color": "#13ce66" },
            model: {
              value: _vm.valueProxy.download,
              callback: function($$v) {
                _vm.$set(_vm.valueProxy, "download", $$v)
              },
              expression: "valueProxy.download"
            }
          })
        ],
        1
      ),
      _vm._v(" "),
      _vm.valueProxy.download
        ? _c(
            "el-form-item",
            { staticClass: "download-price", attrs: { label: "下载价格" } },
            [
              _c("el-input-number", {
                attrs: {
                  disabled: _vm.allowEdit,
                  "controls-position": "right",
                  min: 0
                },
                model: {
                  value: _vm.downloadPrice,
                  callback: function($$v) {
                    _vm.downloadPrice = $$v
                  },
                  expression: "downloadPrice"
                }
              }),
              _vm._v(" 元\n    "),
              _c(
                "el-checkbox",
                {
                  staticClass: "download-check-vip",
                  attrs: { disabled: _vm.allowEdit },
                  model: {
                    value: _vm.valueProxy.downloadVipFree,
                    callback: function($$v) {
                      _vm.$set(_vm.valueProxy, "downloadVipFree", $$v)
                    },
                    expression: "valueProxy.downloadVipFree"
                  }
                },
                [_vm._v("VIP免费")]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }