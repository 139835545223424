var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "role-content-dialog" },
    [
      _c(
        "el-dialog",
        {
          staticClass: "select-resource-dialog",
          attrs: {
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            width: "700px",
            visible: _vm.dialogShow,
            title: "添加/编辑角色"
          },
          on: {
            "update:visible": function($event) {
              _vm.dialogShow = $event
            },
            close: _vm.handleClose
          }
        },
        [
          _c(
            "div",
            { staticClass: "dislog-content" },
            [
              _c(
                "el-form",
                {
                  ref: "ruleForm",
                  staticClass: "role-form",
                  attrs: {
                    model: _vm.roleForm,
                    rules: _vm.roles,
                    "label-width": "100px"
                  }
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名称", prop: "name" } },
                    [
                      _c("el-input", {
                        attrs: { clearable: "", placeholder: "请输入角色名称" },
                        model: {
                          value: _vm.roleForm.name,
                          callback: function($$v) {
                            _vm.$set(_vm.roleForm, "name", $$v)
                          },
                          expression: "roleForm.name"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色名翻译" } },
                    [
                      _c("el-input", {
                        attrs: { placeholder: "请输入角色名翻译" },
                        model: {
                          value: _vm.roleForm.translateName,
                          callback: function($$v) {
                            _vm.$set(_vm.roleForm, "translateName", $$v)
                          },
                          expression: "roleForm.translateName"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "角色头像" } },
                    [
                      _c("cover-upload", {
                        attrs: {
                          path: _vm.path,
                          "partner-open-id": _vm.partnerOpenId,
                          coverarr: _vm.coverArr,
                          covertext: _vm.coverText,
                          type: "角色",
                          disabledEdit: false
                        },
                        on: {
                          deletecover: _vm.deletecoverFun,
                          changeImg: _vm.changeImg
                        },
                        model: {
                          value: _vm.roleCover,
                          callback: function($$v) {
                            _vm.roleCover = $$v
                          },
                          expression: "roleCover"
                        }
                      }),
                      _vm._v(" "),
                      _c("el-input", {
                        directives: [
                          {
                            name: "show",
                            rawName: "v-show",
                            value: false,
                            expression: "false"
                          }
                        ],
                        model: {
                          value: _vm.roleCover,
                          callback: function($$v) {
                            _vm.roleCover = $$v
                          },
                          expression: "roleCover"
                        }
                      })
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "标签" } },
                    [
                      _c(
                        "el-select",
                        {
                          staticClass: "labek-select",
                          staticStyle: { width: "100%" },
                          attrs: {
                            multiple: "",
                            filterable: "",
                            "allow-create": "",
                            "default-first-option": "",
                            placeholder: "请选择"
                          },
                          model: {
                            value: _vm.label,
                            callback: function($$v) {
                              _vm.label = $$v
                            },
                            expression: "label"
                          }
                        },
                        _vm._l(_vm.labelList, function(item, index) {
                          return _c("el-option", {
                            key: index,
                            attrs: { label: item, value: item }
                          })
                        }),
                        1
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "el-form-item",
                    { attrs: { label: "AI头像" } },
                    [
                      _c("el-input", {
                        attrs: {
                          type: "textarea",
                          rows: 2,
                          placeholder: "请输入AI头像描述"
                        },
                        model: {
                          value: _vm.roleForm.prompt,
                          callback: function($$v) {
                            _vm.$set(_vm.roleForm, "prompt", $$v)
                          },
                          expression: "roleForm.prompt"
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "ai-cover-content" },
                        [
                          _c("cover-upload", {
                            attrs: {
                              path: _vm.path,
                              "partner-open-id": _vm.partnerOpenId,
                              coverarr: _vm.coverArr,
                              covertext: _vm.coverText,
                              "cover-show": false,
                              type: "角色",
                              texttips: "上传",
                              disabledEdit: false
                            },
                            on: { deletecover: _vm.deleteAiCoverFun },
                            model: {
                              value: _vm.roleForm.aiCover,
                              callback: function($$v) {
                                _vm.$set(_vm.roleForm, "aiCover", $$v)
                              },
                              expression: "roleForm.aiCover"
                            }
                          }),
                          _vm._v(" "),
                          _c(
                            "div",
                            { staticClass: "tip-btn" },
                            [
                              _c("div", { staticClass: "tip" }, [
                                _c("i", {
                                  staticClass: "el-icon-info tip-icon"
                                }),
                                _vm._v(
                                  " 若生成失败或与预期不符，可调整描述重新生成"
                                )
                              ]),
                              _vm._v(" "),
                              _c(
                                "el-button",
                                {
                                  staticClass: "btn",
                                  attrs: {
                                    type: "primary",
                                    loading: _vm.aiLoading,
                                    disabled: _vm.aiLoading
                                  },
                                  on: { click: _vm.getAiCover }
                                },
                                [_vm._v("生成")]
                              )
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "dialog-tools" }, [
            _c(
              "div",
              { staticClass: "input-num" },
              [
                _c("span", [_vm._v("排序")]),
                _vm._v(" "),
                _c("el-input-number", {
                  staticStyle: { width: "112px" },
                  attrs: { "controls-position": "right", min: 0 },
                  model: {
                    value: _vm.sort,
                    callback: function($$v) {
                      _vm.sort = $$v
                    },
                    expression: "sort"
                  }
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticStyle: {
                  display: "flex",
                  "align-items": "center",
                  "margin-right": "22px"
                }
              },
              [
                _c("el-button", { on: { click: _vm.handleClose } }, [
                  _vm._v("取消")
                ]),
                _vm._v(" "),
                _c(
                  "el-button",
                  {
                    attrs: { type: "primary" },
                    on: {
                      click: function($event) {
                        return _vm.addRoleInfo()
                      }
                    }
                  },
                  [_vm._v("确定")]
                )
              ],
              1
            )
          ])
        ]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }