var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.isUpload && !_vm.htmlType
      ? _c("div", { staticClass: "file-upload-box" }, [
          _c(
            "div",
            { staticClass: "file-upload-bg" },
            [
              !_vm.uploadDisabled
                ? _c("div", { staticClass: "file-txt" }, [_vm._v("点击上传")])
                : _c("el-progress", {
                    staticClass: "upload-progress",
                    attrs: {
                      percentage: _vm.authProgress,
                      type: "circle",
                      width: 30
                    }
                  }),
              _vm._v(" "),
              _c("input", {
                ref: "uploadControl",
                staticClass: "file-upload",
                attrs: {
                  disabled: _vm.uploadDisabled || _vm.disabledEdit,
                  accept: _vm.inputAccept,
                  type: "file"
                },
                on: {
                  change: function($event) {
                    return _vm.fileChange($event, "audio")
                  }
                }
              })
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "upload-txt-bg" }, [
            _c("i", {
              staticClass: "el-icon-info",
              staticStyle: { color: "#409EFF" }
            }),
            _vm._v(" "),
            _c("span", { staticClass: "upload-txt" }, [
              _vm._v(
                "仅限上传 " +
                  _vm._s(_vm.acceptFileTypeDesc) +
                  " 文件，且不超过10M。"
              )
            ])
          ])
        ])
      : !_vm.htmlType
      ? _c("div", { staticClass: "file-completed-box" }, [
          _c("div", { staticClass: "file-txt" }, [
            _c("i", { staticClass: "iconfont icon-audio" }),
            _vm._v(_vm._s(_vm.resultData.fileName)),
            _c(
              "span",
              { staticClass: "icon-del", on: { click: _vm.delAudio } },
              [_vm._v("×")]
            )
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "file-resource-bg" }, [
            _c("div", { staticClass: "file-resource-txt" }, [
              _vm._v("VID: " + _vm._s(_vm.resultData.vid))
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "file-resource" }, [
              _vm.resultData.fileURL
                ? _c("audio", {
                    ref: "audio",
                    attrs: {
                      id: "audio",
                      src: _vm.resultData.fileURL,
                      controls: "controls"
                    }
                  })
                : _vm._e()
            ])
          ])
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.htmlType
      ? _c(
          "div",
          {
            staticClass: "audio-box",
            staticStyle: {
              display: "flex",
              "flex-wrap": "wrap",
              "line-height": "2"
            }
          },
          [
            !_vm.resultData.fileName || _vm.uploadDisabled
              ? _c(
                  "div",
                  { staticClass: "file-upload-bg-word" },
                  [
                    !_vm.uploadDisabled
                      ? _c(
                          "el-button",
                          {
                            staticStyle: { "line-height": "8px" },
                            attrs: { size: "small", type: "primary", plain: "" }
                          },
                          [_vm._v("上传")]
                        )
                      : _c("el-progress", {
                          staticClass: "upload-progress",
                          attrs: {
                            percentage: _vm.authProgress,
                            type: "circle",
                            width: 31
                          }
                        }),
                    _vm._v(" "),
                    _c("input", {
                      ref: "uploadControl",
                      staticClass: "file-upload",
                      attrs: { accept: ".mp3,.wma", type: "file" },
                      on: {
                        change: function($event) {
                          return _vm.fileChange($event, "audio")
                        }
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.resultData.fileName && !_vm.uploadDisabled
              ? _c("div", { staticClass: "video-box" }, [
                  _c(
                    "div",
                    {
                      staticClass: "list-box",
                      staticStyle: { display: "flex" }
                    },
                    [
                      _c("el-image", {
                        attrs: {
                          src: require("../../assets/images/md-music_note.svg")
                        }
                      }),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "file-resource-txt",
                          staticStyle: { "margin-left": "10px" }
                        },
                        [_vm._v("VID: " + _vm._s(_vm.resultData.vid))]
                      )
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      staticClass: "video-button",
                      staticStyle: { display: "flex", "align-items": "center" }
                    },
                    [
                      _c(
                        "el-tooltip",
                        {
                          staticClass: "item",
                          attrs: {
                            effect: "dark",
                            content: "替换",
                            placement: "top"
                          }
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "replace-btn" },
                            [
                              _c("el-image", {
                                staticClass: "video-check",
                                attrs: {
                                  src: require("../../assets/images/md-swap_horiz.svg")
                                }
                              }),
                              _vm._v(" "),
                              _c("input", {
                                ref: "uploadControl",
                                staticClass: "file-upload",
                                attrs: { accept: ".mp3,.wma", type: "file" },
                                on: {
                                  change: function($event) {
                                    return _vm.fileChange($event, "audio")
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ]
                      ),
                      _vm._v(" "),
                      ["word", "ancientPoetry"].includes(_vm.htmlType)
                        ? _c("i", {
                            staticClass: "el-icon-delete delete-icon",
                            on: { click: _vm.delAudio }
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("el-image", {
                        staticClass: "video-not-check",
                        attrs: {
                          src: require("../../assets/images/md-check_circle.svg")
                        }
                      })
                    ],
                    1
                  )
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.resultData.fileName && !_vm.uploadDisabled
              ? _c("div", { staticClass: "file-resource-bg" }, [
                  _c("div", { staticClass: "file-resource" }, [
                    _vm.resultData.fileURL
                      ? _c("audio", {
                          ref: "audio",
                          attrs: {
                            id: "audio",
                            src: _vm.resultData.fileURL,
                            controls: "controls"
                          }
                        })
                      : _vm._e()
                  ])
                ])
              : _vm._e()
          ]
        )
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }