var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "one-click-stocking-table-layout" }, [
    _c(
      "div",
      { staticClass: "one-click-stocking-table" },
      [
        _c(
          "el-table",
          {
            directives: [
              {
                name: "loading",
                rawName: "v-loading",
                value: _vm.mLoading,
                expression: "mLoading"
              }
            ],
            staticStyle: { width: "100%" },
            attrs: {
              data: _vm.distributionOfGoodsData,
              border: "",
              "header-cell-class-name": "table-header-cell",
              height: "calc(100vh - 268px)",
              "span-method": _vm.distributionOfGoodsSpanMethod
            },
            scopedSlots: _vm._u([
              {
                key: "append",
                fn: function() {
                  return [
                    _c(
                      "div",
                      { staticClass: "append-line" },
                      [
                        _c("icon-button", {
                          on: { click: _vm.addDistributionOfGoodsData }
                        })
                      ],
                      1
                    )
                  ]
                },
                proxy: true
              }
            ])
          },
          [
            _c("el-table-column", {
              attrs: {
                type: "index",
                width: "50",
                label: "序号",
                "header-align": "center",
                align: "center"
              }
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "seriesCode",
                label: "系列/品牌",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "brand-series-layout" },
                        [
                          _c("brand-selector", {
                            on: {
                              change: function($event) {
                                return _vm.updateProductQuantityDebounced(
                                  scope.$index
                                )
                              }
                            },
                            model: {
                              value: scope.row.brandOpenId,
                              callback: function($$v) {
                                _vm.$set(scope.row, "brandOpenId", $$v)
                              },
                              expression: "scope.row.brandOpenId"
                            }
                          }),
                          _vm._v(" "),
                          _c("series-selector", {
                            attrs: { "brand-open-id": scope.row.brandOpenId },
                            on: {
                              change: function($event) {
                                return _vm.updateProductQuantityDebounced(
                                  scope.$index
                                )
                              }
                            },
                            model: {
                              value: scope.row.seriesCode,
                              callback: function($$v) {
                                _vm.$set(scope.row, "seriesCode", $$v)
                              },
                              expression: "scope.row.seriesCode"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "groupIds",
                label: "商品分组",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("group-selector", {
                        attrs: {
                          "second-group-ids": scope.row.secondGroupIds,
                          "middle-open-id": _vm.middleOpenId,
                          "partner-open-id": _vm.partnerOpenId
                        },
                        on: {
                          "update:secondGroupIds": function($event) {
                            return _vm.$set(scope.row, "secondGroupIds", $event)
                          },
                          "update:second-group-ids": function($event) {
                            return _vm.$set(scope.row, "secondGroupIds", $event)
                          },
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.groupIds,
                          callback: function($$v) {
                            _vm.$set(scope.row, "groupIds", $$v)
                          },
                          expression: "scope.row.groupIds"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "subjectCode",
                label: "学科",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "div",
                        { staticClass: "subject-layout" },
                        [
                          _c("subject-selector", {
                            on: {
                              change: function($event) {
                                return _vm.updateProductQuantityDebounced(
                                  scope.$index
                                )
                              }
                            },
                            model: {
                              value: scope.row.subjectCode,
                              callback: function($$v) {
                                _vm.$set(scope.row, "subjectCode", $$v)
                              },
                              expression: "scope.row.subjectCode"
                            }
                          }),
                          _vm._v(" "),
                          _vm.distributionOfGoodsIsLast(scope)
                            ? _c("icon-button", {
                                on: {
                                  click: function($event) {
                                    return _vm.addSubDistributionOfGoodsData(
                                      scope
                                    )
                                  }
                                }
                              })
                            : _c("icon-button", {
                                attrs: {
                                  type: "danger",
                                  icon: "el-icon-minus"
                                },
                                on: {
                                  click: function($event) {
                                    return _vm.minusSubDistributionOfGoodsData(
                                      scope
                                    )
                                  }
                                }
                              })
                        ],
                        1
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "editionCode",
                label: "版本",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("edition-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.editionCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "editionCode", $$v)
                          },
                          expression: "scope.row.editionCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "sectionCode",
                label: "学段",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("section-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.sectionCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "sectionCode", $$v)
                          },
                          expression: "scope.row.sectionCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "gradeCode",
                label: "年级",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("grade-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.gradeCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "gradeCode", $$v)
                          },
                          expression: "scope.row.gradeCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "volumeCode",
                label: "阶段",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("volume-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.volumeCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "volumeCode", $$v)
                          },
                          expression: "scope.row.volumeCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "areaCode",
                label: "地区",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("area-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.areaCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "areaCode", $$v)
                          },
                          expression: "scope.row.areaCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "electiveCode",
                label: "选必修",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("elective-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.electiveCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "electiveCode", $$v)
                          },
                          expression: "scope.row.electiveCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "periodCode",
                label: "版次",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("period-selector", {
                        on: {
                          change: function($event) {
                            return _vm.updateProductQuantityDebounced(
                              scope.$index
                            )
                          }
                        },
                        model: {
                          value: scope.row.periodCode,
                          callback: function($$v) {
                            _vm.$set(scope.row, "periodCode", $$v)
                          },
                          expression: "scope.row.periodCode"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "middleStockPrice",
                label: "中盘进货折扣",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "batch-set-price-layout" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("中盘进货折扣")
                          ]),
                          _vm._v(" "),
                          _c("discount-input", {
                            on: { blur: _vm.onBatchMiddleStockPrice },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchMiddleStockPrice($event)
                              }
                            },
                            model: {
                              value: _vm.batchMiddleStockPrice,
                              callback: function($$v) {
                                _vm.batchMiddleStockPrice = $$v
                              },
                              expression: "batchMiddleStockPrice"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-input", {
                        model: {
                          value: scope.row.middleStockPrice,
                          callback: function($$v) {
                            _vm.$set(scope.row, "middleStockPrice", $$v)
                          },
                          expression: "scope.row.middleStockPrice"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "shopStockPrice",
                label: "店铺的进货折扣",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "batch-set-price-layout" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("店铺的进货折扣")
                          ]),
                          _vm._v(" "),
                          _c("discount-input", {
                            on: { blur: _vm.onBatchShopStockPrice },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchShopStockPrice($event)
                              }
                            },
                            model: {
                              value: _vm.batchShopStockPrice,
                              callback: function($$v) {
                                _vm.batchShopStockPrice = $$v
                              },
                              expression: "batchShopStockPrice"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-input", {
                        model: {
                          value: scope.row.shopStockPrice,
                          callback: function($$v) {
                            _vm.$set(scope.row, "shopStockPrice", $$v)
                          },
                          expression: "scope.row.shopStockPrice"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "shopLowPrice",
                label: "店铺最低销售折扣",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "batch-set-price-layout" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("店铺最低销售折扣")
                          ]),
                          _vm._v(" "),
                          _c("discount-input", {
                            on: { blur: _vm.onBatchShopLowPrice },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchShopLowPrice($event)
                              }
                            },
                            model: {
                              value: _vm.batchShopLowPrice,
                              callback: function($$v) {
                                _vm.batchShopLowPrice = $$v
                              },
                              expression: "batchShopLowPrice"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-input", {
                        model: {
                          value: scope.row.shopLowPrice,
                          callback: function($$v) {
                            _vm.$set(scope.row, "shopLowPrice", $$v)
                          },
                          expression: "scope.row.shopLowPrice"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "shopPrice",
                label: "店铺默认销售折扣",
                width: "180",
                "header-align": "center",
                align: "center"
              },
              scopedSlots: _vm._u([
                {
                  key: "header",
                  fn: function() {
                    return [
                      _c(
                        "div",
                        { staticClass: "batch-set-price-layout" },
                        [
                          _c("div", { staticClass: "label" }, [
                            _vm._v("店铺默认销售折扣")
                          ]),
                          _vm._v(" "),
                          _c("discount-input", {
                            on: { blur: _vm.onBatchShopPrice },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchShopPrice($event)
                              }
                            },
                            model: {
                              value: _vm.batchShopPrice,
                              callback: function($$v) {
                                _vm.batchShopPrice = $$v
                              },
                              expression: "batchShopPrice"
                            }
                          })
                        ],
                        1
                      )
                    ]
                  },
                  proxy: true
                },
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c("discount-input", {
                        model: {
                          value: scope.row.shopPrice,
                          callback: function($$v) {
                            _vm.$set(scope.row, "shopPrice", $$v)
                          },
                          expression: "scope.row.shopPrice"
                        }
                      })
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "阶梯价格1",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountA },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountA($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountA,
                              callback: function($$v) {
                                _vm.batchProductCountA = $$v
                              },
                              expression: "batchProductCountA"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.productPreferentialList[0]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[0],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[0].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceA },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceA($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceA,
                              callback: function($$v) {
                                _vm.batchPriceA = $$v
                              },
                              expression: "batchPriceA"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value: scope.row.productPreferentialList[0].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[0],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[0].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "阶梯价格2",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountB },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountB($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountB,
                              callback: function($$v) {
                                _vm.batchProductCountB = $$v
                              },
                              expression: "batchProductCountB"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.productPreferentialList[1]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[1],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[1].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceB },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceB($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceB,
                              callback: function($$v) {
                                _vm.batchPriceB = $$v
                              },
                              expression: "batchPriceB"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value: scope.row.productPreferentialList[1].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[1],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[1].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "阶梯价格3",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountC },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountC($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountC,
                              callback: function($$v) {
                                _vm.batchProductCountC = $$v
                              },
                              expression: "batchProductCountC"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.productPreferentialList[2]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[2],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[2].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceC },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceC($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceC,
                              callback: function($$v) {
                                _vm.batchPriceC = $$v
                              },
                              expression: "batchPriceC"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value: scope.row.productPreferentialList[2].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.productPreferentialList[2],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.productPreferentialList[2].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "拼单阶梯价格1",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountD },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountD($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountD,
                              callback: function($$v) {
                                _vm.batchProductCountD = $$v
                              },
                              expression: "batchProductCountD"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[0]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[0],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[0].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceD },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceD($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceD,
                              callback: function($$v) {
                                _vm.batchPriceD = $$v
                              },
                              expression: "batchPriceD"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[0].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[0],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[0].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "拼单阶梯价格2",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountE },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountE($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountE,
                              callback: function($$v) {
                                _vm.batchProductCountE = $$v
                              },
                              expression: "batchProductCountE"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[1]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[1],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[1].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceE },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceE($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceE,
                              callback: function($$v) {
                                _vm.batchPriceE = $$v
                              },
                              expression: "batchPriceE"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[1].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[1],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[1].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-table-column",
              {
                attrs: {
                  label: "拼单阶梯价格3",
                  width: "180",
                  "header-align": "center",
                  align: "center"
                }
              },
              [
                _c("el-table-column", {
                  attrs: {
                    label: "册数",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("count-input", {
                            attrs: { "left-text": "册数" },
                            on: { blur: _vm.onBatchProductCountF },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchProductCountF($event)
                              }
                            },
                            model: {
                              value: _vm.batchProductCountF,
                              callback: function($$v) {
                                _vm.batchProductCountF = $$v
                              },
                              expression: "batchProductCountF"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("count-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[2]
                                  .productCount,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[2],
                                  "productCount",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[2].productCount"
                            }
                          })
                        ]
                      }
                    }
                  ])
                }),
                _vm._v(" "),
                _c("el-table-column", {
                  attrs: {
                    label: "折扣",
                    width: "180",
                    "header-align": "center",
                    align: "center"
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "header",
                      fn: function() {
                        return [
                          _c("discount-input", {
                            attrs: { "left-text": "折扣" },
                            on: { blur: _vm.onBatchPriceF },
                            nativeOn: {
                              keyup: function($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.onBatchPriceF($event)
                              }
                            },
                            model: {
                              value: _vm.batchPriceF,
                              callback: function($$v) {
                                _vm.batchPriceF = $$v
                              },
                              expression: "batchPriceF"
                            }
                          })
                        ]
                      },
                      proxy: true
                    },
                    {
                      key: "default",
                      fn: function(scope) {
                        return [
                          _c("discount-input", {
                            model: {
                              value:
                                scope.row.splicingPreferentialList[2].price,
                              callback: function($$v) {
                                _vm.$set(
                                  scope.row.splicingPreferentialList[2],
                                  "price",
                                  $$v
                                )
                              },
                              expression:
                                "scope.row.splicingPreferentialList[2].price"
                            }
                          })
                        ]
                      }
                    }
                  ])
                })
              ],
              1
            ),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                prop: "prCount",
                label: "数量",
                width: "60",
                "header-align": "center",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "tz-quantity",
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.onQuantityClicked(scope)
                            }
                          }
                        },
                        [
                          _vm._v(
                            _vm._s(_vm._f("numberFilter")(scope.row.prCount))
                          )
                        ]
                      )
                    ]
                  }
                }
              ])
            }),
            _vm._v(" "),
            _c("el-table-column", {
              attrs: {
                label: "操作",
                width: "60",
                "header-align": "center",
                align: "center",
                fixed: "right"
              },
              scopedSlots: _vm._u([
                {
                  key: "default",
                  fn: function(scope) {
                    return [
                      _c(
                        "el-button",
                        {
                          staticClass: "tz-remove",
                          attrs: { type: "text" },
                          on: {
                            click: function($event) {
                              return _vm.minusSubDistributionOfGoodsData(scope)
                            }
                          }
                        },
                        [_vm._v("移除")]
                      )
                    ]
                  }
                }
              ])
            })
          ],
          1
        )
      ],
      1
    ),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "dialogs" },
      [
        _c("product-information", {
          attrs: {
            "query-data": _vm.productInformationData,
            "middle-open-id": _vm.middleOpenId
          },
          model: {
            value: _vm.productInformationShow,
            callback: function($$v) {
              _vm.productInformationShow = $$v
            },
            expression: "productInformationShow"
          }
        })
      ],
      1
    )
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }